import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

const App = () => {
  const [lang, setLang] = useState();
  const [key, setKey] = useState();

  useEffect(() => {
    let currLang = localStorage.getItem("lang");
    if (currLang == undefined || currLang == "undefined") {
      localStorage.setItem("lang", "cz");
    }
  }, [])

  useEffect(() => {
    if (lang == undefined || lang == "undefined") return;
    localStorage.setItem("lang", lang);
    setKey(Math.random());
  }, [lang])

  return (
    <Router key={key} lang={lang}>
      <Header changeLanguage={setLang} />
      <AppRouter />
    </Router>
  );
};

export default App;
