import React, { useEffect, useState } from 'react';
import './TitleSection.css';
import Translator from '../../translates/Translator';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';


const HeroSection = (props) => {
    const t = Translator();

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [])

    const onScroll = () => {
        let titleHintElement = document.getElementById('title-hint-text');
        if (titleHintElement) {
            if (window.scrollY > titleHintElement.offsetTop - 400) {
                titleHintElement.classList.add("opacity-zero");
            } else {
                titleHintElement.classList.remove("opacity-zero");
            }
        }
        let servicesHintElement = document.getElementById('service-hint-text');
        if (servicesHintElement) {
            if (window.scrollY > servicesHintElement.offsetTop - 400) {
                servicesHintElement.classList.add("opacity-zero");
            } else {
                servicesHintElement.classList.remove("opacity-zero");
            }
        }
    }

    return (
        <div id='TitleSection' className={"title page-section"}>
            <h1 className='title-text-moto'>{t("ikadata-moto")}</h1>
            {/* <h1 className='title-text-name'>IKA DATA</h1> */}
            {/* <h1 className='title-text-name'>IKA DATA s.r.o.</h1> */}
            <h1 className='title-text-name'>IKA DATA, spol. s r.o.</h1>
            <h1 className='title-text-description'>{t("ikadata-description")}</h1>

            <p id='title-hint-text' className='hint-text title-hint fs-5'>
                <i className="fa-solid fa-turn-down fa-flip-horizontal color-tertiary fa-xs me-2"></i>
                {t("our-services")}
            </p>


            {/* <Row>
                <Col className='home-title px-5'>
                    <h1 className='title-ika'>IKA</h1>
                    <h1 className='title-data'>DATA</h1>
                </Col>
                <Col className='text-uppercase align-content-center px-5'>
                    <p className="home-subtitle">jednička</p>
                    <p className="home-subtitle">na trhu</p>
                    <p className="home-subtitle">s <span className='color-primary'>CAFM</span> řešením</p>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col className='d-flex justify-content-end'>
                    <button onClick={scrollToServices} className='title-button'>
                        {t("learn-more")}
                        <i class="fa-solid fa-turn-down"></i>
                    </button>
                </Col>
                <code className='color-tertiary'>{animatedText}</code>
            </Row> */}
        </div>
    );
};

export default HeroSection;
