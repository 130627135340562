import React, { useState, useEffect, useRef } from 'react';
import TitleSection from './TitleSection';
import ServicesSection from './ServicesSection';
import ReferencesSection from './ReferencesSection';
import { ContactSection } from '../Contact';
import './HomePage.css';
import Translator from '../../translates/Translator';
import {
    Container,
} from 'react-bootstrap';

const HomePage = () => {
    const t = Translator();

    return (
        <div className='page-container home-page'>
            <Container className='section-container'>
                <TitleSection />
                <ServicesSection />
                <ReferencesSection />
                <ContactSection />
            </Container>
        </div>
    );
};

export default HomePage;
