import React from 'react';
import HexagonHiveBuilder from "../../HexagonHiveBuilder";
import Footer from '../../../../components/footer/Footer'
import Translator from '../../../../translates/Translator';
import {
    Container,
    Row,
    Tabs,
    Tab
} from 'react-bootstrap';

const ConditionAssessmentModule = () => {
    const t = Translator();

    return (
        <div className='module-page-container'>
            <HexagonHiveBuilder module="building_management" />
            <Container className='module-content'>
                <Row className='mb-5'>
                    <h2>{t("m2p1-content").title}</h2>
                    <p>{t("m2p1-content").description}</p>
                </Row>
                <Row className='mb-5'>
                    <h2>{t("Benefits")}</h2>
                    <ul className='ms-3 module-list'>
                        <li>{t("m2p1-content").benefit[0]}</li>
                        <li>{t("m2p1-content").benefit[1]}</li>
                        <li>{t("m2p1-content").benefit[2]}</li>
                        <li>{t("m2p1-content").benefit[3]}</li>
                        <li>{t("m2p1-content").benefit[4]}</li>
                    </ul>
                </Row>
                <Row className='mb-5'>
                    <h2>{t("Solutions")}</h2>
                    <Tabs
                        defaultActiveKey="tab1"
                        id="solutionTabs"
                        className="solution-tabs m-3 pe-0"
                        justify
                    >
                        <Tab eventKey="tab1" title={t("m2p1-content").solution[0].title}>
                            <p className='ms-2'>{t("m2p1-content").solution[0].description}</p>
                            <ul className='module-list'>
                                <li>{t("m2p1-content").solution[0].features[0]}</li>
                                <li>{t("m2p1-content").solution[0].features[1]}</li>
                                <li>{t("m2p1-content").solution[0].features[2]}</li>
                                <li>{t("m2p1-content").solution[0].features[3]}</li>
                                <li>{t("m2p1-content").solution[0].features[4]}</li>
                            </ul>
                        </Tab>
                        <Tab eventKey="tab2" title={t("m2p1-content").solution[1].title}>
                            <p className='ms-2'>{t("m2p1-content").solution[1].description}</p>
                            <ul className='module-list'>
                                <li>{t("m2p1-content").solution[1].features[0]}</li>
                                <li>{t("m2p1-content").solution[1].features[1]}</li>
                                <li>{t("m2p1-content").solution[1].features[2]}</li>
                                <li>{t("m2p1-content").solution[1].features[3]}</li>
                                <li>{t("m2p1-content").solution[1].features[4]}</li>
                            </ul>
                        </Tab>
                        <Tab eventKey="tab3" title={t("m2p1-content").solution[2].title}>
                            <p className='ms-2'>{t("m2p1-content").solution[2].description}</p>
                            <ul className='module-list'>
                                <li>{t("m2p1-content").solution[2].features[0]}</li>
                                <li>{t("m2p1-content").solution[2].features[1]}</li>
                                <li>{t("m2p1-content").solution[2].features[2]}</li>
                                <li>{t("m2p1-content").solution[2].features[3]}</li>
                                <li>{t("m2p1-content").solution[2].features[4]}</li>
                            </ul>
                        </Tab>
                        <Tab eventKey="tab4" title={t("m2p1-content").solution[3].title}>
                            <p className='ms-2'>{t("m2p1-content").solution[3].description}</p>
                            <ul className='module-list'>
                                <li>{t("m2p1-content").solution[3].features[0]}</li>
                                <li>{t("m2p1-content").solution[3].features[1]}</li>
                                <li>{t("m2p1-content").solution[3].features[2]}</li>
                                <li>{t("m2p1-content").solution[3].features[3]}</li>
                                <li>{t("m2p1-content").solution[3].features[4]}</li>
                            </ul>
                        </Tab>
                    </Tabs>
                </Row>
                <Footer />
            </Container>
        </div>
    );
};

export default ConditionAssessmentModule;
