import React from 'react';
import HexagonHiveBuilder from "../HexagonHiveBuilder";

const ExpansionAndTechnology = () => {
    return (
        <HexagonHiveBuilder module="expansion_and_technology"/>
    );
};

export default ExpansionAndTechnology;
