import { useEffect, useRef } from 'react';
import cz from './translate-cz.json';
import en from './translate-en.json';


export default function Translator() {
    const resources = {
        "cz": cz,
        "en": en
    }
    const translates = useRef(resources.cz);

    function setTranslatorLanguage() {
        let lang = localStorage.getItem("lang");
        if(lang != "undefined") {
            translates.current = resources[lang];
        } else {
            translates.current = resources.cz;
        }
    }

    const translate = (text) => {
        if (localStorage.getItem("lang") != translates.current[text.lang]) setTranslatorLanguage();
        let translatedText = translates.current[text.toLowerCase()];
        return (translatedText == undefined) ? text : translatedText;
    }

    return translate;
}