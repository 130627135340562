import React from 'react';
import HexagonHiveBuilder from "../HexagonHiveBuilder";

const AssetManagement = () => {
    return (
        <HexagonHiveBuilder module="asset_management"/>
    );
};

export default AssetManagement;
