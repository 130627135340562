import React from 'react';
import { Link } from 'react-router-dom';
import './Hexagon.css';
import translator from '../../translates/Translator';


const prepareRows = (hexagons) => {
    const rows = [];
    const maxRows = Math.max(...hexagons.map(hex => hex.row));
    const maxColumns = Math.max(...hexagons.map(hex => hex.column));

    // Initialize the grid with null values
    for (let i = 0; i < maxRows; i++) {
        rows[i] = new Array(maxColumns).fill(null);
    }

    hexagons.forEach(hex => {
        rows[hex.row - 1][hex.column - 1] = hex;
    });

    return rows;
};

const HexagonBuilder = ({ hexagons , isMainModulePage }) => {
    const rows = prepareRows(hexagons);
    const t = translator();

    return (
        <div className="hive">
            {rows.map((row, rowIndex) => (
                <div key={rowIndex} className={rowIndex % 2 === 0 ? 'hex-row' : 'hex-alt-row'}>
                    {row.map((hex, hexIndex) => (
                        hex ? (
                            <Link
                                key={hexIndex}
                                to={((hex.isMain && isMainModulePage) || (!hex.isMain)) ? hex.to : "/archibus-modules" }
                                className={(hex.isMain && !isMainModulePage) ? 'back-link-hex' : 'hex'}
                                style={{
                                    gridColumn: hex.column,
                                    gridRow: hex.row
                                }}
                            >
                                {t(hex.translateKey)}
                            </Link>
                        ) : (
                            <div
                                key={hexIndex}
                                className="hex-placeholder"
                                style={{
                                    gridColumn: hexIndex + 1,
                                    gridRow: rowIndex + 1
                                }}
                            />
                        )
                    ))}
                </div>
            ))}
        </div>
    );
};

export default HexagonBuilder;
