import React from 'react';
import './Footer.css';
import Translator from '../../translates/Translator';

const Footer = () => {
    const t = Translator();
    const date = new Date().getFullYear();
    
    return (
        <footer className='footer'>
            <p className='m-0'>&copy; {date} {t("footer-copyright")}</p>
        </footer>
    );
};

export default Footer;
