import { useEffect, useState } from 'react';

const Languages = (props) => {
    // const [langValue, setLangValue] = useState("cz");

    // useEffect(() => {
    //     let currLang = localStorage.getItem("lang");
    //     if(currLang != undefined) setLangValue(currLang);
    // })

    return (
        <select className='emojiFont' defaultValue={localStorage.getItem("lang")}
            onChange={(e) => { props.changeLanguage(e.target.value); }}>
            {/* {console.log("langselect",localStorage.getItem("lang"))} */}
            <option value={'cz'}>&#127464;&#127487;</option>
            {/* <option value={'en'} className="lang-en">&#127468;&#127463;</option> */}
            <option value={'en'}>🇺🇸</option>
        </select>
    );
}

export default Languages;