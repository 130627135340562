import React from 'react';
import HexagonHiveBuilder from "../HexagonHiveBuilder";

const PropertyPortfolioManagement = () => {
    return (
        <HexagonHiveBuilder module="portfolio_management"/>
    );
};

export default PropertyPortfolioManagement;
