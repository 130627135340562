import React from 'react';
import './ReferencesSection.css';
import {
    Row,
    Col,
    Figure
} from 'react-bootstrap';
import Translator from '../../translates/Translator';

const ReferenceSection = () => {
    const t = Translator();
    const imgSize = 80;

    const partnersDataSet = [
        {
            name: "ARCHIBUS",
            imgSrc: "archibus_logo.png"
        },
        {
            name: "archibus-hosting",
            imgSrc: "archibus_hosting_logo.jpg"
        },
        {
            name: "Eptura",
            imgSrc: "eptura_logo.png"
        },
        {
            name: "AZURE",
            imgSrc: "azure_logo.png"
        }
    ]

    const referencesDataSet = [
        {
            name: "ČSOB",
            imgSrc: "CSOB_logo.png"
        },
        {
            name: "Skoda-Auto",
            imgSrc: "skoda_logo.png"
        },
        {
            name: "Kooperativa",
            imgSrc: "koop_logo.png"
        },
        {
            name: "Ceska-posta",
            imgSrc: "ceskaposta_logo.png"
        },
        {
            name: "Masarykova-Univerzita",
            imgSrc: "mu_logo.png"
        },
        {
            name: "Windmoller-Holscher",
            imgSrc: "windmuller_logo.png"
        },
        {
            name: "Inex",
            imgSrc: "inex_logo.png"
        },
        {
            name: "aseta-group",
            imgSrc: "aseta_logo.png"
        },
        {
            name: "Digiteq-Automotive",
            imgSrc: "digiteq_logo.png"
        },
        {
            name: "Surpmo",
            imgSrc: "surpmo_logo.png"
        },
        {
            name: "Siemens",
            imgSrc: "siemens_logo.png"
        },
        {
            name: "vscr",
            imgSrc: "vscr_logo.png"
        },
        {
            name: "saudi-aramco",
            imgSrc: "saudi_aramco_logo.jpg"
        },
        {
            name: "qatar-foundation",
            imgSrc: "qatar_foundation_logo.png"
        }
    ]

    return (
        <div id='ReferencesSection' className={'references page-section'}>
            <h4 id='partners-section-header' className='section-header'>
                {t("partners")}
            </h4>
            <div className='content-container'>
                <Row className='m-0 md-gy-4 gy-3'>
                    {partnersDataSet.map(p => {
                        let imgPath = "/assets/img/reference_logos/" + p.imgSrc
                        return (
                            <Col key={p.name} className='' xs="6" md="3">
                                <div className="reference-block">
                                    <Figure className=''>
                                        <Figure.Image
                                            width={imgSize}
                                            height={imgSize}
                                            alt="img"
                                            src={imgPath}
                                        />
                                    </Figure>
                                    <p>
                                        {t(p.name)}
                                    </p>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
            <h4 id='references-section-header' className='section-header mt-5'>
                {t("references")}
            </h4>
            <div className='content-container'>
                <Row className='m-0 mb-5 md-gy-4 gy-3'>
                    {referencesDataSet.map(p => {
                        let imgPath = "/assets/img/reference_logos/" + p.imgSrc
                        return (
                            <Col key={p.name} className='' xs="6" md="3">
                                <div className="reference-block">
                                    <Figure className=''>
                                        <Figure.Image
                                            width={imgSize}
                                            height={imgSize}
                                            alt="img"
                                            src={imgPath}
                                        />
                                    </Figure>
                                    <p>
                                        {t(p.name)}
                                    </p>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};

export default ReferenceSection;
