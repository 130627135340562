export const hexagons = [

    { to: "/archibus-modules/asset-management", label: "Asset Management", row: 1, column: 1, isMain: true, module: "asset_management", translateKey: "module1" },
    { to: "/archibus-modules/asset-management/asset-management", label: "Asset Management", row: 1, column: 3, module: "asset_management", translateKey: "module1-page1" },
    { to: "/archibus-modules/asset-management/cable-distribution-and-telecommunications", label: "Cable Distribution and Telecommunications", row: 2, column: 2, module: "asset_management", translateKey: "module1-page2" },
    { to: "/archibus-modules/asset-management/corporate-asset-management", label: "Corporate Asset Management", row: 3, column: 2, module: "asset_management", translateKey: "module1-page3" },

    { to: "/archibus-modules/building-management", label: "Building and Maintenance Management", row: 1, column: 2, isMain: true, module: "building_management", translateKey: "module2" },
    { to: "/archibus-modules/building-management/condition-assessment", label: "Area Allocation", row: 2, column: 3, module: "building_management", translateKey: "module2-page1" },
    { to: "/archibus-modules/building-management/preventive-maintenance", label: "Summary of Areas and Their Performance", row: 3, column: 2, module: "building_management", translateKey: "module2-page2" },
    { to: "/archibus-modules/building-management/required-maintenance", label: "Removal", row: 3, column: 3, module: "building_management", translateKey: "module2-page3" },

    { to: "/archibus-modules/area-management", label: "Area Management", row: 1, column: 3, isMain: true, module: "area_management", translateKey: "module3" },
    { to: "/archibus-modules/area-management/area-allocation", label: "Area Allocation", row: 1, column: 5, module: "area_management", translateKey: "module3-page1-removed" },
    { to: "/archibus-modules/area-management/summary-of-areas-performance", label: "Summary of Areas and Their Performance", row: 2, column: 4, module: "area_management", translateKey: "module3-page1" },
    { to: "/archibus-modules/area-management/move", label: "Removal", row: 2, column: 5, module: "area_management", translateKey: "module3-page2" },
    { to: "/archibus-modules/area-management/strategic-area-planning", label: "Strategic Area Planning", row: 3, column: 4, module: "area_management", translateKey: "module3-page3" },
    { to: "/archibus-modules/area-management/staff-occupancy", label: "Staff and Occupancy", row: 3, column: 5, module: "area_management", translateKey: "module3-page4" },

    { to: "/archibus-modules/property-portfolio-management", label: "Property Portfolio Management", row: 2, column: 1, isMain: true, module: "portfolio_management", translateKey: "module4" },
    { to: "/archibus-modules/property-portfolio-management/advanced-portfolio-planning", label: "Advanced Portfolio Planning", row: 1, column: 3, module: "portfolio_management", translateKey: "module4-page1" },
    { to: "/archibus-modules/property-portfolio-management/billing-invoicing", label: "Billing and Invoicing", row: 1, column: 4, module: "portfolio_management", translateKey: "module4-page2" },
    { to: "/archibus-modules/property-portfolio-management/expense-management", label: "Expense Management", row: 2, column: 3, module: "portfolio_management", translateKey: "module4-page3" },
    { to: "/archibus-modules/property-portfolio-management/extensions-rental-accounting", label: "Extensions for Rental Accounting", row: 2, column: 4, module: "portfolio_management", translateKey: "module4-page4" },
    { to: "/archibus-modules/property-portfolio-management/portfolio-management", label: "Portfolio Management", row: 3, column: 3, module: "portfolio_management", translateKey: "module4-page5" },
    { to: "/archibus-modules/property-portfolio-management/rental-management", label: "Rental Management", row: 3, column: 4, module: "portfolio_management", translateKey: "module4-page6" },
    { to: "/archibus-modules/property-portfolio-management/strategic-financial-analysis", label: "Strategic Financial Analysis", row: 3, column: 5, module: "portfolio_management", translateKey: "module4-page7" },

    { to: "/archibus-modules/services-and-workplace", label: "Services and Workplaces", row: 2, column: 2, isMain: true, module: "services_and_workplace", translateKey: "module5" },
    { to: "/archibus-modules/services-and-workplace/hosteling", label: "Hotelling", row: 1, column: 4, module: "services_and_workplace", translateKey: "module5-page1" },
    { to: "/archibus-modules/services-and-workplace/reservations", label: "Reservations", row: 2, column: 4, module: "services_and_workplace", translateKey: "module5-page2" },
    { to: "/archibus-modules/services-and-workplace/service-desk", label: "Service Desk", row: 3, column: 4, module: "services_and_workplace", translateKey: "module5-page3" },

    { to: "/archibus-modules/capital-project-management", label: "Capital Project and Financial Management", row: 2, column: 3, isMain: true, module: "financial_management", translateKey: "module6" },
    { to: "/archibus-modules/capital-project-management/building-commissioning", label: "Building Commissioning", row: 1, column: 2, module: "financial_management", translateKey: "module6-page1" },
    { to: "/archibus-modules/capital-project-management/financial-planning-and-budgeting", label: "Financial Planning and Budgeting", row: 2, column: 1, module: "financial_management", translateKey: "module6-page2" },
    { to: "/archibus-modules/capital-project-management/project-management", label: "Project Management", row: 3, column: 2, module: "financial_management", translateKey: "module6-page3" },

    { to: "/archibus-modules/risk-and-environmental-management", label: "Risk and Environmental Management", row: 3, column: 1, isMain: true, module: "risk_management", translateKey: "module7" },
    { to: "/archibus-modules/risk-and-environmental-management/clean-buildings", label: "Clean Buildings", row: 1, column: 1, module: "risk_management", translateKey: "module7-page1" },
    { to: "/archibus-modules/risk-and-environmental-management/compliance-management", label: "Compliance Management", row: 1, column: 2, module: "risk_management", translateKey: "module7-page2" },
    { to: "/archibus-modules/risk-and-environmental-management/emergency-preparedness", label: "Emergency Preparedness", row: 1, column: 3, module: "risk_management", translateKey: "module7-page3" },
    { to: "/archibus-modules/risk-and-environmental-management/energy-management", label: "Energy Management", row: 2, column: 2, module: "risk_management", translateKey: "module7-page4" },
    { to: "/archibus-modules/risk-and-environmental-management/hazardous-waste-management", label: "Hazardous Waste Management", row: 2, column: 3, module: "risk_management", translateKey: "module7-page5" },
    { to: "/archibus-modules/risk-and-environmental-management/health-safety", label: "Health and Safety", row: 2, column: 4, module: "risk_management", translateKey: "module7-page6" },
    { to: "/archibus-modules/risk-and-environmental-management/sustainable-buildings", label: "Sustainable Buildings (Green Buildings)", row: 3, column: 3, module: "risk_management", translateKey: "module7-page7" },
    { to: "/archibus-modules/risk-and-environmental-management/sustainable-development", label: "Sustainable Development", row: 3, column: 4, module: "risk_management", translateKey: "module7-page8" },
    { to: "/archibus-modules/risk-and-environmental-management/waste-management", label: "Waste Management", row: 3, column: 5, module: "risk_management", translateKey: "module7-page9" },

    { to: "/archibus-modules/expansion-and-technology", label: "Expansion and Technology", row: 3, column: 2, isMain: true, module: "expansion_and_technology", translateKey: "module8" },
    { to: "/archibus-modules/expansion-and-technology/esri-gis-extensions", label: "Extensions for ESRI GIS", row: 1, column: 2, module: "expansion_and_technology", translateKey: "module8-page1" },
    { to: "/archibus-modules/expansion-and-technology/performance-evaluation", label: "Performance Evaluation", row: 1, column: 3, module: "expansion_and_technology", translateKey: "module8-page2" },
    { to: "/archibus-modules/expansion-and-technology/revit-extension", label: "Extension for Revit", row: 2, column: 3, module: "expansion_and_technology", translateKey: "module8-page3" },

];
