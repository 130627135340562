import React from 'react';
import HexagonHiveBuilder from "../HexagonHiveBuilder";

const ServicesAndWorkplace = () => {
    return (
        <HexagonHiveBuilder module="services_and_workplace"/>
    );
};

export default ServicesAndWorkplace;
