import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import main pages
import HomePage from './pages/home_page/HomePage';
import AboutUs from './pages/AboutUs';
import { Contact } from './pages/Contact';

// Import general css for main pages
import './pages/Pages.css';

// Import Archibus module pages and sub-modules
import AreaManagement from './pages/archibus_modules/area_management/AreaManagement';
import AreaAllocationModule from './pages/archibus_modules/area_management/sub_modules/AreaAllocationModule';
import MoveModule from './pages/archibus_modules/area_management/sub_modules/MoveModule';
import StaffOccupancyModule from './pages/archibus_modules/area_management/sub_modules/StaffOccupancyModule';
import StrategicAreaPlanningModule from './pages/archibus_modules/area_management/sub_modules/StrategicAreaPlanningModule';
import SummaryOfAreasPerformanceModule from './pages/archibus_modules/area_management/sub_modules/SummaryOfAreasPerformanceModule';

import AssetManagement from './pages/archibus_modules/asset_management/AssetManagement';
import AssetManagementModule from './pages/archibus_modules/asset_management/sub_modules/AssetManagementModule';
import CableDistributionAndTelecommunicationsModule from './pages/archibus_modules/asset_management/sub_modules/CableDistributionAndTelecommunicationsModule';
import CorporateAssetManagementModule from './pages/archibus_modules/asset_management/sub_modules/CorporateAssetManagementModule';

import BuildingManagement from './pages/archibus_modules/building_management/BuildingManagement';
import ConditionAssessmentModule from './pages/archibus_modules/building_management/sub_modules/ConditionAssessmentModule';
import PreventiveMaintenanceModule from './pages/archibus_modules/building_management/sub_modules/PreventiveMaintenanceModule';
import RequiredMaintenanceModule from './pages/archibus_modules/building_management/sub_modules/RequiredMaintenanceModule';

import CapitalProjectManagement from './pages/archibus_modules/capital_project_management/CapitalProjectManagement';
import BuildingCommissioningModule from './pages/archibus_modules/capital_project_management/sub_modules/BuildingCommissioningModule';
import FinancialPlanningBudgetingModule from './pages/archibus_modules/capital_project_management/sub_modules/FinancialPlanningBudgetingModule';
import ProjectManagementModule from './pages/archibus_modules/capital_project_management/sub_modules/ProjectManagementModule';

import ExpansionAndTechnology from './pages/archibus_modules/expansion_and_technology/ExpansionAndTechnology';
import ESRIGISExtensionsModule from './pages/archibus_modules/expansion_and_technology/sub_modules/ESRIGISExtensionsModule';
import PerformanceEvaluationModule from './pages/archibus_modules/expansion_and_technology/sub_modules/PerformanceEvaluationModule';
import RevitExtensionModule from './pages/archibus_modules/expansion_and_technology/sub_modules/RevitExtensionModule';

import PropertyPortfolioManagement from './pages/archibus_modules/property_portfolio_management/PropertyPortfolioManagement';
import AdvancedPortfolioPlanningModule from './pages/archibus_modules/property_portfolio_management/sub_modules/AdvancedPortfolioPlanningModule';
import BillingInvoicingModule from './pages/archibus_modules/property_portfolio_management/sub_modules/BillingInvoicingModule';
import ExpenseManagementModule from './pages/archibus_modules/property_portfolio_management/sub_modules/ExpenseManagementModule';
import ExtensionsRentalAccountingModule from './pages/archibus_modules/property_portfolio_management/sub_modules/ExtensionsRentalAccountingModule';
import PortfolioManagementModule from './pages/archibus_modules/property_portfolio_management/sub_modules/PortfolioManagementModule';
import RentalManagementModule from './pages/archibus_modules/property_portfolio_management/sub_modules/RentalManagementModule';
import StrategicFinancialAnalysisModule from './pages/archibus_modules/property_portfolio_management/sub_modules/StrategicFinancialAnalysisModule';

import RiskAndEnvironmentalManagement from './pages/archibus_modules/risk_and_environmenta_management/RiskAndEnvironmentalManagement';
import CleanBuildingsModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/CleanBuildingsModule';
import ComplianceManagementModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/ComplianceManagementModule';
import EmergencyPreparednessModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/EmergencyPreparednessModule';
import EnergyManagementModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/EnergyManagementModule';
import HazardousWasteManagementModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/HazardousWasteManagementModule';
import HealthSafetyModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/HealthSafetyModule';
import SustainableBuildingsModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/SustainableBuildingsModule';
import SustainableDevelopmentModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/SustainableDevelopmentModule';
import WasteManagementModule from './pages/archibus_modules/risk_and_environmenta_management/sub_modules/WasteManagementModule';

import ServicesAndWorkplace from './pages/archibus_modules/services_and_workplace/ServicesAndWorplace';
import HotellingModule from './pages/archibus_modules/services_and_workplace/sub_modules/HotellingModule';
import ReservationsModule from './pages/archibus_modules/services_and_workplace/sub_modules/ReservationsModule';
import ServiceDeskModule from './pages/archibus_modules/services_and_workplace/sub_modules/ServiceDeskModule';
import MainModules from "./pages/archibus_modules/MainModules";
import SimpleArchibusModules from './pages/archibus_modules/SimpleArchibusModules';

const AppRouter = () => {
    return (

        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />

            {/* Archibus Modules Routes */}
            {/* <Route path="/archibus-modules" element={<MainModules />} /> */}
            <Route path="/archibus-modules" element={<SimpleArchibusModules />} />

            <Route path="/archibus-modules/area-management" element={<AreaManagement />} />
            <Route path="/archibus-modules/area-management/area-allocation" element={<AreaAllocationModule />} />
            <Route path="/archibus-modules/area-management/move" element={<MoveModule />} />
            <Route path="/archibus-modules/area-management/staff-occupancy" element={<StaffOccupancyModule />} />
            <Route path="/archibus-modules/area-management/strategic-area-planning" element={<StrategicAreaPlanningModule />} />
            <Route path="/archibus-modules/area-management/summary-of-areas-performance" element={<SummaryOfAreasPerformanceModule />} />

            <Route path="/archibus-modules/asset-management" element={<AssetManagement />} />
            <Route path="/archibus-modules/asset-management/asset-management" element={<AssetManagementModule />} />
            <Route path="/archibus-modules/asset-management/cable-distribution-and-telecommunications" element={<CableDistributionAndTelecommunicationsModule />} />
            <Route path="/archibus-modules/asset-management/corporate-asset-management" element={<CorporateAssetManagementModule />} />

            <Route path="/archibus-modules/building-management" element={<BuildingManagement />} />
            <Route path="/archibus-modules/building-management/condition-assessment" element={<ConditionAssessmentModule />} />
            <Route path="/archibus-modules/building-management/preventive-maintenance" element={<PreventiveMaintenanceModule />} />
            <Route path="/archibus-modules/building-management/required-maintenance" element={<RequiredMaintenanceModule />} />

            <Route path="/archibus-modules/capital-project-management" element={<CapitalProjectManagement />} />
            <Route path="/archibus-modules/capital-project-management/building-commissioning" element={<BuildingCommissioningModule />} />
            <Route path="/archibus-modules/capital-project-management/financial-planning-and-budgeting" element={<FinancialPlanningBudgetingModule />} />
            <Route path="/archibus-modules/capital-project-management/project-management" element={<ProjectManagementModule />} />

            <Route path="/archibus-modules/expansion-and-technology" element={<ExpansionAndTechnology />} />
            <Route path="/archibus-modules/expansion-and-technology/esri-gis-extensions" element={<ESRIGISExtensionsModule />} />
            <Route path="/archibus-modules/expansion-and-technology/performance-evaluation" element={<PerformanceEvaluationModule />} />
            <Route path="/archibus-modules/expansion-and-technology/revit-extension" element={<RevitExtensionModule />} />

            <Route path="/archibus-modules/property-portfolio-management" element={<PropertyPortfolioManagement />} />
            <Route path="/archibus-modules/property-portfolio-management/advanced-portfolio-planning" element={<AdvancedPortfolioPlanningModule />} />
            <Route path="/archibus-modules/property-portfolio-management/billing-invoicing" element={<BillingInvoicingModule />} />
            <Route path="/archibus-modules/property-portfolio-management/expense-management" element={<ExpenseManagementModule />} />
            <Route path="/archibus-modules/property-portfolio-management/extensions-rental-accounting" element={<ExtensionsRentalAccountingModule />} />
            <Route path="/archibus-modules/property-portfolio-management/portfolio-management" element={<PortfolioManagementModule />} />
            <Route path="/archibus-modules/property-portfolio-management/rental-management" element={<RentalManagementModule />} />
            <Route path="/archibus-modules/property-portfolio-management/strategic-financial-analysis" element={<StrategicFinancialAnalysisModule />} />

            <Route path="/archibus-modules/risk-and-environmental-management" element={<RiskAndEnvironmentalManagement />} />
            <Route path="/archibus-modules/risk-and-environmental-management/clean-buildings" element={<CleanBuildingsModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/compliance-management" element={<ComplianceManagementModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/emergency-preparedness" element={<EmergencyPreparednessModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/energy-management" element={<EnergyManagementModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/hazardous-waste-management" element={<HazardousWasteManagementModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/health-safety" element={<HealthSafetyModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/sustainable-buildings" element={<SustainableBuildingsModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/sustainable-development" element={<SustainableDevelopmentModule />} />
            <Route path="/archibus-modules/risk-and-environmental-management/waste-management" element={<WasteManagementModule />} />

            <Route path="/archibus-modules/services-and-workplace" element={<ServicesAndWorkplace />} />
            <Route path="/archibus-modules/services-and-workplace/hoteling" element={<HotellingModule />} />
            <Route path="/archibus-modules/services-and-workplace/reservations" element={<ReservationsModule />} />
            <Route path="/archibus-modules/services-and-workplace/service-desk" element={<ServiceDeskModule />} />
        </Routes>

    );
};

export default AppRouter;
