import React from 'react';
import './ServicesSection.css';
import Translator from '../../translates/Translator';
import {
    Row,
    Col
} from 'react-bootstrap';

const ServicesSection = (props) => {
    const t = Translator();

    return (
        <div id='ServicesSection' className={'services page-section'}>
            <h4 className='section-header color-tertiary'>{t("our-services")}</h4>
            <div className='content-container py-4'>
                <Row className='my-3 mx-0'>
                    <Col className='pe-5' xs='12' md='6'>
                        <h3 className='color-primary title-underline underline-color-primary'>{t("service1-title")}</h3>
                        <p>{t("service1-desc")}</p>
                    </Col>
                    <Col className='ps-5'>
                        {/* <Image src="/assets/img/services-cafm.jpg" fluid className='carousel-image' /> */}
                    </Col>
                </Row>
                <Row className='my-3 mx-0'>
                    <Col className='pe-5' xs='12' md='6'>
                        {/* <Image src="/assets/img/services-fm.jpg" fluid className='carousel-image' /> */}
                    </Col>
                    <Col className='md-ps-5 pe-5 pe-md-3'>
                        <h3 className='color-primary title-underline underline-color-primary'>{t("service2-title")}</h3>
                        <p>{t("service2-desc")}</p>
                    </Col>
                </Row>
                <Row className='my-3 mx-0'>
                    <Col className='pe-5' xs='12' md='6'>
                        <h3 className='color-primary title-underline underline-color-primary'>{t("service3-title")}</h3>
                        <p>{t("service3-desc")}</p>
                    </Col>
                    <Col className='ps-5 align-content-end'>
                        {/* <Image src="/assets/img/services-pasp.jpg" fluid className='carousel-image' /> */}
                        <p id='service-hint-text' className='hint-text color-tertiary text-end md-me-5'>
                            {t("references-partners")}
                            <i className="fa-solid fa-turn-down color-tertiary fa-xs ms-2"></i>
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ServicesSection;