import React from "react";
import Footer from '../../components/footer/Footer';
import Translator from "../../translates/Translator";
import {
    Row,
    Col,
    Figure
} from 'react-bootstrap';

const SimpleArchibusModules = () => {
    const t = Translator();

    return (
        <div className='page-container'>
            <div className='container section-container'>
                <div id='ArchibusSection' className='archibus page-section'>
                    <Row className="m-0 mt-4 px-4 px-md-4 mx-md-5">
                        <Col xs={12} md={3} className="align-content-center">
                            <Figure className=''>
                                <Figure.Image
                                    alt="Archibus Logo"
                                    src="/assets/img/reference_logos/archibus_logo.png"
                                />
                            </Figure>
                        </Col>
                        <Col xs={12} md={8} className="offset-md-1">
                            <h2 className="mb-3">{t("archibus-title1")}</h2>
                            <p>{t("archibus-desc1")}</p>
                            <p className="mb-0">{t("archibus-desc2")}</p>
                        </Col>
                    </Row>
                    <h4 className="my-4 text-center color-tertiary">{t("archibus-title2")}</h4>
                    <Row className='m-0 mt-3 mb-5 px-3 mx-md-5 px-md-5 row-gap-4'>
                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module1")}</h5></Col>
                                <Col xs={2} className=''>
                                    {/* <i class="fa-solid fa-magnifying-glass-dollar fa-2xl"></i> */}
                                    <i class="fa-solid fa-hand-holding-dollar fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module1-page1")}</li>
                                        <li>{t("module1-page2")}</li>
                                        <li>{t("module1-page3")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module2")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-screwdriver-wrench fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module2-page1")}</li>
                                        <li>{t("module2-page2")}</li>
                                        <li>{t("module2-page3")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module3")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-map-location-dot fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module3-page1")}</li>
                                        <li>{t("module3-page2")}</li>
                                        <li>{t("module3-page3")}</li>
                                        <li>{t("module3-page4")}</li>
                                        <li>{t("module3-page5")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module5")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-headset fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module5-page1")}</li>
                                        <li>{t("module5-page2")}</li>
                                        <li>{t("module5-page3")}</li>
                                        <li>{t("module5-page4")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module6")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-magnifying-glass-dollar fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module6-page1")}</li>
                                        <li>{t("module6-page2")}</li>
                                        <li>{t("module6-page3")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={4}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module8")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-plug-circle-plus fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module8-page1")}</li>
                                        <li>{t("module8-page2")}</li>
                                        <li>{t("module8-page3")}</li>
                                        <li>{t("module8-page4")}</li>
                                        <li>{t("module8-page5")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={1}></Col>
                        <Col xs={12} lg={5}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module4")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-building-circle-check fa-2xl"></i>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module4-page1")}</li>
                                        <li>{t("module4-page2")}</li>
                                        <li>{t("module4-page3")}</li>
                                        <li>{t("module4-page4")}</li>
                                        <li>{t("module4-page5")}</li>
                                        <li>{t("module4-page6")}</li>
                                        <li>{t("module4-page7")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} lg={5}>
                            <Row>
                                <Col xs={12}><h5 className='title-underline-short'>{t("module7")}</h5></Col>
                                <Col xs={2} className=''>
                                    <i class="fa-solid fa-seedling fa-2xl"></i>
                                    {/* <i class="fa-solid fa-earth-europe fa-2xl"></i> */}
                                </Col>
                                <Col>
                                    <ul>
                                        <li>{t("module7-page1")}</li>
                                        <li>{t("module7-page2")}</li>
                                        <li>{t("module7-page3")}</li>
                                        <li>{t("module7-page4")}</li>
                                        <li>{t("module7-page5")}</li>
                                        <li>{t("module7-page6")}</li>
                                        <li>{t("module7-page7")}</li>
                                        <li>{t("module7-page8")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Footer />
                </div >
            </div>
        </div >
    );
}

export default SimpleArchibusModules;