import React, { useState, useEffect } from 'react';
import './Header.css';  // Add a separate CSS file for styling
import {
    Container,
    Row,
    Col,
    Nav,
    Dropdown
} from 'react-bootstrap';
import LanguagesSelect from '../LanguagesSelect';
import Translator from '../../translates/Translator';

const Header = (props) => {
    const t = Translator();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    //initial function
    useEffect(() => {
        window.addEventListener("resize", resize);
    }, [])

    const resize = () => {
        (window.innerWidth < 768) ? setIsMobile(true) : setIsMobile(false);
    }

    // window.onscroll = function () { headerScrollResize() };
    // function headerScrollResize() {
    //     if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
    //         document.getElementById("header").style.padding = "10px";
    //         document.getElementById("headerLogo").style.width = "66px";
    //     } else {
    //         document.getElementById("header").style.padding = "27px";
    //         document.getElementById("headerLogo").style.width = "100px";
    //     }
    // }

    const headerBody = () => {
        return isMobile ? (
            <div className='justify-content-center d-flex'>
                <Nav.Link href="/" className=''>
                    <img
                        id='headerLogo'
                        alt=""
                        src="/logo.png"
                        width="44"
                        className="ikaLogoSmall"
                    />
                </Nav.Link>
                <h3 className='mt-3 fw-bold'>IKA&nbsp;DATA</h3>
                <Dropdown className='menu'>
                    <Dropdown.Toggle variant="primary">
                        &equiv;
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/">{t("Home")}</Dropdown.Item>
                        <Dropdown.Item href="/about-us">{t("About-us")}</Dropdown.Item>
                        <Dropdown.Item href="/archibus-modules">{t("Modules")}</Dropdown.Item>
                        <Dropdown.Item href="/contact">{t("Contact")}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        ) : (
            <Row>
                <Col md={3} sm={12} className='brandColumn p-0'>
                    <Nav.Link href="/" className='d-flex align-items-center'>
                        <img
                            id='headerLogo'
                            alt=""
                            src="/logo.png"
                            className="ikaLogo"
                        />
                        <h3 className='ms-3'>IKA&nbsp;DATA</h3>
                    </Nav.Link>
                </Col>
                <Col className='d-flex navPages'>
                    <Nav className="">
                        <Nav.Link href="/" className='fs-5 px-lg-4'>{t("Home")}</Nav.Link>
                        <Nav.Link href="/about-us" className='fs-5 px-lg-4'>{t("About-us")}</Nav.Link>
                        <Nav.Link href="/archibus-modules" className='fs-5 px-lg-4'>{t("Modules")}</Nav.Link>
                        <Nav.Link href="/contact" className='fs-5 px-lg-4'>{t("Contact")}</Nav.Link>
                    </Nav>
                    <LanguagesSelect changeLanguage={props.changeLanguage} />
                </Col>
            </Row>
        );
    }

    return (
        <header className="header" id='header'>
            <Container>
                {headerBody()}
            </Container>
        </header>
    );
};

export default Header;
