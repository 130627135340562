
import React from 'react';
import HexagonBuilder from './HexagonBuilder';
import { hexagons } from './HexagonData';
import './ArchibusModules.css';

const HexagonHiveBuilder = ({ module , isMainModulePage }) => {
    // Filter hexagons based on the module
    let filteredHexagons = ""
    isMainModulePage ?
        filteredHexagons= hexagons.filter(hex => hex.isMain === true)  :
        filteredHexagons= hexagons.filter(hex => hex.module === module)

    return (
            <div className="modules-div">
                <HexagonBuilder hexagons={filteredHexagons} isMainModulePage={isMainModulePage} />
            </div>
    );
};

export default HexagonHiveBuilder;
