import React from 'react';
import './Contact.css';
import {
    Row,
    Col
} from 'react-bootstrap';
import Footer from '../components/footer/Footer';
import Translator from '../translates/Translator';

export const Contact = () => {
    return (
        <div className='page-container'>
            <div className='container section-container'>
                <ContactSection fullModule={true}/>
            </div>
        </div>
    );
};


export const ContactSection = (props) => {
    const t = Translator();

    return (
        <div id='ContactSection' className='contact page-section'>
            <h2 id='contact-section-header' className='mt-4'>{t("Contact")}</h2>
            <Row className='md-ms-4'>
                <Col xs={12} lg={4} className='contact-info mb-4'>
                    <Row>
                        <Col xs={2} className=''>
                            <i className="fa-solid fa-pen-to-square fa-2xl"></i>
                        </Col>
                        <Col>
                            <span className='fs-5'>IKA DATA, spol. s r.o.</span><br />
                            Vinohradská 1899/112,
                            130 00 Praha 3<br />
                            IČ: 26155664
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2} className=''>
                            <i className="fa-solid fa-mobile-screen-button fa-2xl"></i>
                        </Col>
                        <Col>
                            Tel.: <a href="tel:+420601563562" className='fs-6'>+420 601 563 562</a> <br />
                            Helpdesk: <a href="tel:+420771506197" className='fs-6'>+420 771 506 197</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2} className=''>
                            <i className="fa-regular fa-envelope fa-2xl"></i>
                        </Col>
                        <Col>
                            <a href="mailto:sluzby@ikadata.com" className='fs-6'>Sluzby@ikadata.com</a> <br />
                            <a href="mailto:helpdesk@ikadata.com" className='fs-6'>Helpdesk@ikadata.com</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2} className=''>
                            <i className="fa-solid fa-location-dot fa-2xl"></i>
                        </Col>
                        <Col>
                            <span className='fs-5'>{t("premises-address")}</span><br />
                            U Průhonu 773/12<br />
                            170 00 Praha 7 - Holešovice
                        </Col>
                    </Row>
                </Col>
                <Col className='d-flex justify-content-center map-div mb-5'>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d452.37454204737134!2d14.445399379632773!3d50.104691567978634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94b410e55c07%3A0x463a9ad5db80bbff!2sU%20Pr%C5%AFhonu%20773%2C%20170%2000%20Praha%207-Hole%C5%A1ovice!5e0!3m2!1scs!2scz!4v1591267928492!5m2!1scs!2scz" width="600" height="430" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                    <iframe
                        title="Address Map"
                        style={{ border: "0", width: "90%", height: "100%" }}
                        loading="lazy"
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB1zlELSUC0cR_7uA29WaOkY&key=AIzaSyCvRA1MTKLf1AydgWlAmiPbH25lXDC3s0M">
                    </iframe>
                </Col>
            </Row>
            <Footer />
        </div>
    );
};