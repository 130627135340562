import React from 'react';
import './AboutUs.css';
import Translator from '../translates/Translator';
import Footer from '../components/footer/Footer';
import {
    Row,
    Col
} from 'react-bootstrap';

const AboutUs = () => {
    const t = Translator();

    return (
        <div className='page-container'>
            <div className='container section-container'>
                <div id='AboutUsSection' className='aboutus page-section'>
                    <Row>
                        <h2 className=''>{t("company-profile")}</h2>
                        {/* <h5>{t("company-profile-title")}</h5> */}
                        <Row className='aboutus-company-profile'>
                            {/* <Col xs="12" md="4">
                            <ul>
                                <li>{t("cp-point1")}</li>
                                <li>{t("cp-point2")}</li>
                                <li>{t("cp-point3")}</li>
                                <li>{t("cp-point4")}</li>
                                <li>{t("cp-point5")}</li>
                            </ul>
                        </Col>
                        <Col xs="12" md="4">
                            <ul>
                                <li>{t("cp-point6")}</li>
                                <li>{t("cp-point7")}</li>
                                <li>{t("cp-point8")}</li>
                                <li>{t("cp-point9")}</li>
                            </ul>
                        </Col>
                        <Col xs="12" md="4">
                            <ul>
                                <li>{t("cp-point10")}</li>
                                <li>{t("cp-point11")}</li>
                                <li>{t("cp-point12")}</li>
                                <li>{t("cp-point13")}</li>
                                <ul>
                                    <li>{t("cp-point13-1")}</li>
                                    <li>{t("cp-point13-2")}</li>
                                    <li>{t("cp-point13-3")}</li>
                                    <li>{t("cp-point13-4")}</li>
                                    <li>{t("cp-point13-5")}</li>
                                </ul>
                            </ul>
                        </Col> */}
                            <p>
                                {t("cp-text1")} <b>{t("cp-bold-text1")}</b>{t("cp-text2")}
                                {/* </p>
                        <p> */}
                                <br />
                                {t("cp-text3")}
                            </p>
                            <p>
                                <b>{t("cp-bold-text2")}</b> {t("cp-text4")} <b>{t("cp-bold-text3")}</b> {t("cp-text5")}
                                <br />
                                <b>{t("cp-bold-text4")}</b> {t("cp-text6")}
                            </p>
                            <p>
                                <b>{t("cp-bold-text5")}</b> {t("cp-text7")}
                            </p>
                            <p>
                                <b>{t("cp-bold-text6")}</b> {t("cp-text8")}
                            </p>
                        </Row>
                    </Row>
                    <Row className="mb-5 aboutus-achievements">
                        <h2 className='mb-0'>{t("achievements")}</h2>
                        {/* <h5>{t("achievement1-title")}</h5>
                    <p>{t("achievement1-desc")}</p> */}
                        <Row>
                            <Col xs="12" md="6">
                                <h5 className='title-underline-short underline-color-tertiary'>{t("achievement1-title")}</h5>
                                <p>{t("achievement1-desc")}</p>
                                <h5 className='title-underline-short underline-color-tertiary'>{t("achievement2-title")}</h5>
                                <p>{t("achievement2-desc")}</p>
                                <h5 className='title-underline-short underline-color-tertiary'>{t("achievement3-title")}</h5>
                                <p>{t("achievement3-desc")}</p>
                                <h5 className='title-underline-short underline-color-tertiary'>{t("achievement4-title")}</h5>
                                <p>{t("achievement4-desc")}</p>
                            </Col>
                            <Col xs="12" md="6" className='archibus-achievement-col'>
                                <h5 className='title-underline-short underline-color-tertiary'>{t("achievement5-title")}</h5>
                                <p>- {t("achievement5-desc1")}</p>
                                <p>- {t("achievement5-desc2")}</p>
                                <p>- {t("achievement5-desc3")}</p>
                            </Col>
                        </Row>
                    </Row>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
